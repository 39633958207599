import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import EmailJS from "@emailjs/browser";

function Contact() {
  const [formdata, setFormdata] = useState({
    to_name: "Anwaar Ul Haq",
    from_name: "",
    email: "",
    from_email: "",
    subject: "",
    message: "",
    reply_to: "anwaarbwp@gmail.com", // Please put your email address here.
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const submitHandler = (event) => {
    event.preventDefault();

    if (!formdata.from_name) {
      setError(true);
      setMessage("Name is required");
      return;
    } else if (!formdata.from_email) {
      setError(true);
      setMessage("Email is required");
      return;
    } else if (!formdata.subject) {
      setError(true);
      setMessage("Subject is required");
      return;
    } else if (!formdata.message) {
      setError(true);
      setMessage("Message is required");
      return;
    }
    EmailJS.sendForm(
      "service_6urykwg", // Please put your service ID if you have Email JS.
      "template_o2yxtgq", // Please put your template ID if you have Email JS.
      event.target,
      "PrQRKjyiIzIXaDvha" // Please put your Public key if you have Email JS.
    ).then(
      (result) => {
        setError(false);
        setMessage("Your message has been sent!!!");
        setFormdata({
          to_name: "Anwar Ul Haq",
          from_name: "",
          email: "",
          from_email: "",
          subject: "",
          message: "",
          reply_to: "anwaarbwp@gmail.com", // Please put your email address here.
        });
      },
      (error) => {
        console.log("Failed:(", error.text);
        setError(true);
        setMessage("Something went wrong!!!");
      }
    );
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <section id="contact">
      <div className="container">
        <Pagetitle title="Get in Touch" />

        <div className="row">
          <div className="col-md-4">
            <div className="contact-info">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h3>Let's talk about everything!</h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                {/* <p>
                  Don't like forms? Send me an <a href="mailto:anwaarbwp@gmail.com">email</a>. 👋
                </p> */}
              </ScrollAnimation>
            </div>
          </div>

          <div className="col-md-8">
            <form
              id="contact-form"
              className="contact-form mt-6"
              onSubmit={submitHandler}
            >
              <div className="row">
                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="from_name"
                      id="InputName"
                      placeholder="Your name"
                      onChange={handleChange}
                      value={formdata.from_name}
                    />
                  </div>
                </div>

                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="InputEmail"
                      name="from_email"
                      placeholder="Please enter your email address"
                      onChange={handleChange}
                      value={formdata.from_email}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="InputSubject"
                      name="subject"
                      placeholder="Subject"
                      onChange={handleChange}
                      value={formdata.subject}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="InputMessage"
                      className="form-control"
                      rows="5"
                      placeholder="Message"
                      onChange={handleChange}
                      value={formdata.message}
                    ></textarea>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                name="submit"
                id="submit"
                value="Submit"
                className="btn btn-default"
              >
                Send Message
              </button>
            </form>
            {handleAlerts()}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
