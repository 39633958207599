import React, { useState } from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import Header from "../components/layouts/Header";
import About from "../components/sections/About";
// import Blogs from "../components/sections/Blogs";
// import Brandlogos from "../components/sections/Brandlogos";
import Contact from "../components/sections/Contact";
import Experiences from "../components/sections/Experiences";
import Herosection from "../components/sections/Herosection";
// import Pricing from "../components/sections/Pricing";
import Services from "../components/sections/Services";
import Testimonials from "../components/sections/Testimonials";
import Works from "../components/sections/Works";
// import { getFirestore } from "firebase/firestore";
// import { firebaseApp } from "../firebase";

function HomepageLight() {
  // const db = getFirestore(firebaseApp);
  document.body.classList.remove("dark");

  const [toggleMenu, setToggleMenu] = useState(false);
  // const [visitorsCount, setVisitorsCount] = useState(0);

  // useEffect(() => {
  //   async function visitordetail() {

  //     const response = await fetch("https://ipapi.co/json/");
  //     const json = await response.json();
  //     localStorage.setItem("key", JSON.stringify(json));

  //     const docRef = collection(db, "visitordetails");

  //     addDoc(docRef, { json })
  //       .then((snapshot) => {
  //         localStorage.setItem("docid", snapshot.id);
  //       })
  //       .catch((error) => console.log("Error", error));

  //     const coll = collection(db, "visitordetails");
  //     const snapshot = await getCountFromServer(coll);
  //     setVisitorsCount(snapshot.data().count);
  //   }
  //   visitordetail();
  // }, []);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  return (
    <>
      {/* Log is here */}
      <Header
        light
        logoSource="/images/AnwarLogo1.png"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <Element name="section-home">
          <ReactCursorPosition>
            <Herosection light />
          </ReactCursorPosition>
        </Element>
        <Element name="section-about">
          <About />
        </Element>
        <Element name="section-services">
          <Services />
        </Element>
        <Element name="section-experiences">
          <Experiences />
        </Element>
        <Element name="section-works">
          <Works />
        </Element>
        {/* <Element name="section-pricing">
          <Pricing />
        </Element> */}
        <Element name="section-testimoninal">
          <Testimonials />
        </Element>
        {/* <Element name="section-brandlogos">
          <Brandlogos />
        </Element> */}
        {/* <Element name="section-blogs">
          <Blogs />
        </Element> */}
        <Element name="section-contact">
          <Contact />
        </Element>
        <div className="spacer" data-height="96"></div>

        {/* {visitorsCount && visitorsCount > 0 && (
          <div className="visitors-count">Visitors: {visitorsCount}</div>
        )} */}
      </main>
    </>
  );
}

export default HomepageLight;
