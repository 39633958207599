import "matchmedia-polyfill";
import "matchmedia-polyfill/matchMedia.addListener"; // Optional: addEventListener support

import React from "react";
import Slider from "react-slick";
import Pagetitle from "../elements/Pagetitle";
import Testimonial from "../elements/Testimonial";

const sliderData = [
  {
    id: 1,
    avatar: "images/Brandon.jpg",
    userName: "WriteSea CEO",
    subtitle: "Brandon Mitchell",
    review:
      "Anwar Ul Haq has been a remarkable addition to the WriteSea team, showcasing exceptional skills in UI/UX, web development, and Automation Testing using Cypress. His ability to craft seamless and user-friendly interfaces, coupled with his dynamic approach to adapting to new technologies, is highly commendable. Anwar's contributions in both front-end development and automated testing have significantly enhanced our projects. His collaborative nature and eagerness to embrace challenges have made him an invaluable asset. Anwar's work at WriteSea is a testament to his technical acumen and innovative mindset.",
  },
  {
    id: 2,
    avatar: "images/avatar-1.svg",
    userName: "Spearhead-Energy",
    subtitle: "CEO",
    review:
      "Anwar is a focused and dedicated professional. He expertly leveraged MERN Stack technologies to deliver robust web solutions that met our requirements and standards. His attention to detail and commitment to excellence were key to the success of our projects. We highly recommend him.",
  },
  {
    id: 3,
    avatar: "images/mehdi.jpeg",
    userName: "University Of New Haven",
    subtitle: "Professor Mehdi Mekni",
    review:
      "Anwar Haq has proven to be an invaluable asset to the Laboratory for Applied Software Engineering Research at the University of New Haven. His expertise in UI/UX and web development is truly commendable, showcasing a profound understanding of the intricacies involved in creating seamless and user-friendly interfaces. Anwar's technical skills are not only solid but also dynamic, allowing him to adapt to the ever-evolving landscape of software engineering.",
  },
  {
    id: 4,
    avatar: "images/avatar-2.svg",
    userName: "CodeAutomation",
    subtitle: "Project Manager",
    review:
      "Anwar is a very focused,Collaborated on outsourced projects for clients in the United States, leveraging MERN Stack technologies to deliver robust web solutions that met client requirements and standards",
  },
  {
    id: 5,
    avatar: "images/avatar-3.svg",
    userName: "Zaryans Consulting pvt",
    subtitle: "CTO",
    review:
      "Anwar Ul Haq was wonderful to work with. Spearheaded front-end development for the company’s ERP-based product, specializing in React.JS. Wrote and maintained React components using JSX syntax and the React API, ensuring the product’s user interface was feature-rich and user-friendly.",
  },
];

function Testimonials() {
  const slidetSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
  };

  return (
    <section id="testimonials">
      <div className="container">
        <div style={{ marginBottom: "2rem" }}>
          <Pagetitle title="Clients & Reviews" />
        </div>
        <Slider {...slidetSettings} className="testimonials-wrapper">
          {sliderData.map((slider) => (
            <Testimonial key={slider.id} slider={slider} />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
