import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";

const filters = [
  {
    id: 1,
    // text: "Everything",
  },
  {
    id: 2,
    // text: "web",
  },
  {
    id: 3,
    // text: "mobile",
  },
];

const allData = [
  {
    id: 1,
    title: "WriteSea",
    category: "web",
    description:
      "Our AI resume review provides a holistic view of how your users can enhance their professional document to land more interviews with potential employers on your platform.",
    image: "images/projects/writesea.png",
    // popupLink: ["images/projects/forest-full.png"],
    link: "https://writesea.com/product/",
  },
  {
    id: 2,
    title: "Code Automation",
    category: "web",
    description:
      "CodeAutomation is a leading software development and testing company, revolutionizing the digital landscape through innovation and expertise. We are dedicated to empowering businesses...",
    image: "images/projects/code-automation.png",
    // popupLink: ["images/projects/pigeon-full.png"],
    link: "https://codeautomation.ai/",
  },
  {
    id: 3,
    title: "Zaryans Consulting Group",
    category: "web",
    description:
      "Zaryans was established in 2013 with a vision to become a proven Bespoke Software development & IT Resource Augmentation Company. Gone through series of experiences over a period...",
    image: "images/projects/zaryan.png",
    // popupLink: ["images/projects/luminary-full.png"],
    link: "https://zaryans.com/about-us/",
  },
  {
    id: 4,
    title: "Spearhead Energy",
    category: "web",
    description:
      "Spearhead Energy was formed with a vision to bring sophisticated energy management techniques to a wider number of organizations. The traditional focus of energy advisory... ",
    image: "images/projects/spearhead.png",
    // popupLink: ["images/projects/raiinmaker-thumbnail.jpeg"],
    link: "https://spearhead-energy.com",
  },
  {
    id: 5,
    title: "LASER",
    category: "web",
    description:
      "LASER is an active research group at the University of New Haven, founded by Dr. Mehdi Mekni. LASER focuses on applied research, conducted with software development practices.",
    image: "images/projects/laser.png",
    // popupLink: ["images/projects/hashmeeting-thumbnail.png"],
    link: "https://laser.newhaven.edu",
  },
  // {
  //   id: 6,
  //   title: "Optimization",
  //   category: "web",
  //   image: "images/projects/our-vision.jpg",
  //   // popupLink: ["images/projects/raiinmaker-thumbnail.jpeg"],
  //   link: "https://laser.newhaven.edu/",
  // },
  // {
  //   id: 6,
  //   title: "Zingaly",
  //   category: "web",
  //   image: "images/projects/zaryanss.png",
  //   // popupLink: ["images/projects/zingaly-thumbnail.png"],
  //   link: "https://zignaly.com/app/login"
  // },
  // {
  //   id: 7,
  //   title: "Latency App",
  //   category: "web",
  //   image: "images/projects/latency-thumbnail.png",
  //   popupLink: ["images/projects/latency-thumbnail.png"],
  //   link: "https://latencyapp.com/explore"
  // },
  // {
  //   id: 8,
  //   title: "Loonoy (Smart Baby Cradle)",
  //   category: "mobile",
  //   image: "images/projects/loonoy-thumbnail.png",
  //   popupLink: ["images/projects/loonoy-thumbnail.png"],
  //   link: "https://play.google.com/store/apps/details?id=com.loonoy_mobile"
  // },
  // {
  //   id: 9,
  //   title: "Fikz (aka Crewchief)",
  //   category: "mobile",
  //   image: "images/projects/fikz-thumbnail.jpeg",
  //   popupLink: ["images/projects/fikz-thumbnail.jpeg"],
  //   link: "https://appadvice.com/app/fikz/1500930851"
  // },
];

function Works() {
  const [getAllItems] = useState(allData);
  // const [dataVisibleCount, setDataVisibleCount] = useState(6);
  // const [dataIncrement] = useState(3);
  // const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  // const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    // setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= 6));
  }, [getAllItems]);

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   setActiveFilter(e.target.textContent.toLowerCase());
  //   let tempData;
  //   if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
  //     tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
  //   } else {
  //     tempData = getAllItems.filter(
  //       (data) =>
  //         data.category === e.target.textContent.toLowerCase() && data.id <= dataVisibleCount
  //     );
  //   }
  //   setVisibleItems(tempData);
  // };

  // const handleLoadmore = (e) => {
  //   e.preventDefault();
  //   let tempCount = dataVisibleCount + dataIncrement;
  //   if (dataVisibleCount > getAllItems.length) {
  //     setNoMorePost(true);
  //   } else {
  //     setDataVisibleCount(tempCount);
  //     if (activeFilter === filters[0].text.toLowerCase()) {
  //       console.log("they are same");
  //       setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
  //     } else {
  //       setVisibleItems(
  //         getAllItems.filter((data) => data.category === activeFilter && data.id <= tempCount)
  //       );
  //     }
  //   }
  // };

  return (
    <section id="works">
      <div className="container">
        <Pagetitle title="Recent Projects" />
        {/* Start Portfolio Filters */}
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <ul className="portfolio-filter list-inline">
            {filters.map((filter) => (
              <li className="list-inline-item">
                {/* <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter
                      ? "text-capitalize current"
                      : "text-capitalize"
                  }
                >
                  {filter.text}
                </button> */}
              </li>
            ))}
          </ul>
        </ScrollAnimation>
        {/* End Portfolio Filters */}

        {/* Start Portfolio Items */}
        <div className="row portfolio-wrapper">
          {visibleItems.map((item) => (
            <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
              <Portfolio portfolio={item} />
            </div>
          ))}
        </div>
        {/* End Portfolio Items */}

        {/* <div className="load-more text-center mt-4">
          <button
            className="btn btn-default"
            onClick={handleLoadmore}
            disabled={noMorePost ? "disabled" : null}
          >
            {noMorePost ? (
              "No more items"
            ) : (
              <span>
                <i className="fas fa-spinner"></i> Load more
              </span>
            )}
          </button>
        </div> */}
      </div>
    </section>
  );
}

export default Works;
