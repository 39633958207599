import React from "react";
import TrackVisibility from "react-on-screen";
// import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Anwar",
  avatarImage: "/images/avatar-2.svg",
  content:
    "Welcome to my portfolio! I am an experienced MS Computer Science graduate with a strong skill set in JavaScript, MERN stack, React JS, Next.js, Selenium-Java, and Cypress Testing. With a proven track record in web application development and project success, I am passionate about leveraging my expertise to contribute to innovative and impactful projects. Explore my work to see how I can bring value to your next venture.",
};

const progressData = [
  {
    id: 1,
    title: "Web App Development",
    percantage: 85,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Mobile App Development",
    percantage: 75,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Backend Development",
    percantage: 70,
    progressColor: "#6C6CE5",
  },
  {
    id: 4,
    title: "Software Testing",
    percantage: 90,
    progressColor: "#098ce5",
  },
];

const skillsData = [
  {
    id: 1,
    title: "React.Js",
    stack: "frontend",
  },
  {
    id: 2,
    title: "Next.Js",
    stack: "frontend",
  },
  {
    id: 3,
    title: "HTML5",
    stack: "frontend",
  },
  {
    id: 4,
    title: "Node.Js",
    stack: "backend",
  },
  {
    id: 5,
    title: "Express.Js",
    stack: "backend",
  },
  {
    id: 6,
    title: "JavaScript",
    stack: "frontend",
  },
  {
    id: 7,
    title: "CSS3",
    stack: "frontend",
  },
  {
    id: 8,
    title: "Tailwind CSS",
    stack: "frontend",
  },
  {
    id: 9,
    title: "JavaScript",
    stack: "frontend",
  },
  {
    id: 10,
    title: "HTML5",
    stack: "frontend",
  },
  {
    id: 11,
    title: "Redux",
    stack: "frontend",
  },
  {
    id: 12,
    title: "SQLite",
    stack: "backend",
  },
  {
    id: 13,
    title: "Radis",
    stack: "backend",
  },
  {
    id: 14,
    title: "MariaDB,",
    stack: "backend",
  },
  {
    id: 15,
    title: "MongoDb | Mongoose",
    stack: "backend",
  },
  {
    id: 16,
    title: "MySQL | Sequelize",
    stack: "backend",
  },
  {
    id: 17,
    title: "PostgreSQL | TypeORM",
    stack: "backend",
  },
  {
    id: 18,
    title: "Cypress",
    stack: "others",
  },
  {
    id: 19,
    title: "Selenium",
    stack: "others",
  },
  {
    id: 20,
    title: "Appium",
    stack: "others",
  },
  {
    id: 21,
    title: "SASS/SCSS",
    stack: "frontend",
  },
  {
    id: 22,
    title: "Material UI",
    stack: "frontend",
  },
  {
    id: 23,
    title: "Styled Components | Bootstrap",
    stack: "frontend",
  },
  {
    id: 24,
    title: "Tailwind CSS",
    stack: "frontend",
  },
  {
    id: 25,
    title: "Stripe",
    stack: "backend",
  },
  {
    id: 26,
    title: "Google Maps | Mapbox",
    stack: "backend",
  },
  // {
  //   id: 27,
  //   title: "Figma | Adobe Xd | Photoshop",
  //   stack: "others",
  // },
  {
    id: 28,
    title: "Crypto Tokens",
    stack: "blockchain",
  },
  {
    id: 29,
    title: "Solidity Smart Contracts",
    stack: "blockchain",
  },
  {
    id: 30,
    title: "Web3.JS",
    stack: "blockchain",
  },
  {
    id: 31,
    title: "Geth",
    stack: "blockchain",
  },
  {
    id: 32,
    title: "Infura",
    stack: "blockchain",
  },
  {
    id: 33,
    title: "NFTs",
    stack: "blockchain",
  },
];

// const counterData = [
//   {
//     id: 1,
//     title: "Projects completed",
//     count: 25,
//     icon: "icon-fire",
//   },
//   {
//     id: 2,
//     title: "Cup of coffee",
//     count: 1217,
//     icon: "icon-cup",
//   },
//   {
//     id: 3,
//     title: "Satisfied clients",
//     count: 10,
//     icon: "icon-people",
//   },
//   {
//     id: 4,
//     title: "Commits Pushed",
//     count: 1335,
//     icon: "icon-badge",
//   },
// ];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <div className="mt-3">
                    <a
                      href="/Anwar_s_Resume.pdf"
                      className="btn btn-default"
                      download
                    >
                      Download CV
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="60"></div>
        <div style={{ margin: "2rem 0" }}>
          <Pagetitle title="Skills/Expertise" />
        </div>
        <div className="row">
          <div className="col-md-12 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-4">
                  <div
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0 0 6px 0 rgba(0,0,0,.075)",
                      color: "#000",
                      padding: "4px 16px",
                    }}
                    className="frontend_box"
                  >
                    <h4 style={{ color: "#e1a407" }}>Frontend</h4>
                    <ul>
                      {skillsData
                        .filter((s) => s.stack === "frontend")
                        .map((progress) => (
                          <li>{progress.title}</li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0 0 6px 0 rgba(0,0,0,.075)",
                      color: "#000",
                      padding: "4px 16px",
                    }}
                    className="box_backend"
                  >
                    <h4 style={{ color: "#ff4c60" }}>Backend</h4>
                    <ul>
                      {skillsData
                        .filter((s) => s.stack === "backend")
                        .map((progress) => (
                          <li>{progress.title}</li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="">
                    <div
                      style={{
                        borderRadius: "10px",
                        boxShadow: "0 0 6px 0 rgba(0,0,0,.075)",
                        color: "#000",
                        padding: "4px 16px",
                      }}
                      className="automation"
                    >
                      <h4 style={{ color: "#098ce5" }}>Automation Testing</h4>
                      <ul>
                        {skillsData
                          .filter((s) => s.stack === "others")
                          .map((progress) => (
                            <li>{progress.title}</li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  <div style={{ marginTop: "1rem" }}>
                    <div
                      style={{
                        borderRadius: "10px",
                        boxShadow: "0 0 6px 0 rgba(0,0,0,.075)",
                        color: "#000",
                        padding: "4px 16px",
                      }}
                      className="automation"
                    >
                      <h4 style={{ color: "#1f895f" }}>Blockchain</h4>
                      <ul>
                        {skillsData
                          .filter((s) => s.stack === "blockchain")
                          .map((progress) => (
                            <li>{progress.title}</li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="spacer" data-height="70"></div>
        {/* <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div> */}
      </div>
    </section>
  );
}

export default About;
