import React from "react";
import Typed from "react-typed";
import { Link } from "react-scroll";
import ParallaxElements from "../elements/ParallaxElements";

function Herosection(props) {
  return (
    <section
      id="home"
      className={
        props.light
          ? "home d-flex align-items-center light"
          : "home d-flex align-items-center"
      }
    >
      <div className="container">
        <div className="intro">
          <img
            src="images/Anwar1.png"
            alt="Anwar"
            className="mb-4 img-fluid rounded-circle"
          />

          <h1 className="mb-2 mt-0">ANWAR UL HAQ</h1>
          <p>
            <Typed
              strings={[
                "",
                "I'm a Front-End developer",
                "I'm an Automation Engineer",
                "I'm a Backend Developer",
                "I'm a Mobile App Developer",
              ]}
              typeSpeed={80}
              backSpeed={40}
              attr="value"
              loop
            >
              <label value></label>
            </Typed>
          </p>

          <ul className="social-icons light list-inline mb-0 mt-4">
            <li className="list-inline-item">
              <a
                href="https://github.com/Anwaar-Ul-Haq"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-github"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.linkedin.com/in/anwaar-ul-haq/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </li>

            <li className="list-inline-item">
              <a
                href="https://www.instagram.com/anwaarphull/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  style={{ color: "#FF4C60" }}
                  className="fab fa-instagram"
                ></i>
              </a>
            </li>
          </ul>

          <div className="mt-4">
            <Link
              className="btn btn-default"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Hire me
            </Link>
          </div>
        </div>

        <div className="scroll-down">
          <Link
            to="section-about"
            spy={true}
            smooth={true}
            duration={500}
            className="mouse-wrapper"
          >
            <span>Scroll Down</span>
            <span className="mouse">
              <span className="wheel"></span>
            </span>
          </Link>
        </div>

        {props.position && (
          <ParallaxElements
            elementDimensions={props.elementDimensions}
            position={props.position}
          />
        )}
      </div>
    </section>
  );
}

export default Herosection;
