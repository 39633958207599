import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "MS Computer Science",
    years: "January 2022 - December 2023",
    content: ["University of New Haven, Connecticut US"],
  },
  {
    id: 2,
    title: "BS Computer Science",
    years: "September 2013-October 2017",
    content: [
      "National College of Business Administration & Economics, Pakistan ",
    ],
  },
];

const experienceData = [
  {
    id: 1,
    title: "Software Engineer @ WriteSea, Tulsa Oklahoma, US",
    years: "March 2024 - Present",
    content: [
      "Skilled in optimizing JavaScript code with ES6+ features, boosting DOM manipulation efficiency.",
      "Developed MERN stack AI product for millions of users, boosting engagement and growth",
      "Developed AI-powered software enabling users to create resumes, cover letters, and job assistance.",
      "Developed reusable components, increasing developer productivity.",
    ],
  },
  {
    id: 2,
    title: "Software Automation Engineer @ WriteSea, New Jersey, US",
    years: "August 2023 - December 2023",
    content: [
      "Develop, maintain, and optimize automated testing frameworks and tools, executing automated test cases from test plans for consistent testing, and enhancing scripts to adapt to functionality changes.",
      "Manage test data accurately, including creating and validating data sets for automated testing while collaborating with QA and development teams to improve testability.",
      "Track, report, and analyze defects discovered during automation, producing detailed reports on test coverage and performance metrics.",
      "Integrate automated tests into CI/CD pipelines for efficient execution across environments and conduct perfor- mance and load testing with optimization recommendations.",
    ],
  },
  {
    id: 3,
    title: "Research Assistant @ University of New Haven, Connecticut US",
    years: "February 2022 - December 2023",
    content: [
      "Analyzed the NFT market, identifying key technologies and trends in blockchain platforms and smart contract languages for NFT development.",
      "Assessed the NFT industry’s competitive landscape, pinpointing leading companies and their technology choices.",
      "Designed and developed a university website with WordPress and Elementor, ensuring it met specific needs through stakeholder collaboration and maintained accurate, up-to-date content.",
    ],
  },
];
const experienceData2 = [
  {
    id: 1,
    title: "Software Engineer @ BrandResumes, New Jersey, US",
    years: "January 2021 - December 2021",
    content: [
      "Led development of SAS Front-End interface with React.JS, boosting engagement and satisfaction.",
      "Delivered a user-centric and visually appealing Front-End, achieving significant improvements in user engagement and satisfaction, thereby contributing to the platform’s success.",
      "Enhanced quality assurance processes by conducting manual testing and developing test scripts, identifying and addressing issues efficiently, and adopting best practices in testing methodologies and front-end development trends.",
    ],
  },
  {
    id: 2,
    title: "Software Engineer @ CodeAutomation, Lahore, Pakistan",
    years: "September 2019 - January 2021",
    content: [
      "Designed and developed the company’s website using the MERN (MongoDB, Express.js, React.js, Node.js) stack, ensuring a seamless and responsive online presence that aligns with organizational objectives.",
      "Collaborated on outsourced projects for clients in the United States, leveraging MERN Stack technologies to deliver robust web solutions that met client requirements and standards.",
    ],
  },
  {
    id: 3,
    title: "Software Engineer @ Zaryans Consulting pvt, Islamabad, Pakistan",
    years: "July 2018 - September 2019",
    content: [
      "Spearheaded front-end development for the company’s ERP-based product, specializing in React.JS.",
      "Wrote and maintained React components using JSX syntax and the React API, ensuring the product’s user interface was feature-rich and user-friendly.",
      "Collaborated seamlessly with cross-functional teams to integrate RESTful APIs and back-end services, enabling dynamic data retrieval and display, and implemented responsive design principles for optimal user experiences across diverse devices and screen sizes.",
    ],
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <div style={{ margin: "2rem 0" }}>
          <Pagetitle title="Experience" />
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
          {/* 2nd Col For Experience */}
          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData2.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-12" style={{ marginTop: "1rem" }}>
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
